import { Box, BoxProps, withStyles, WithStyles, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { equals } from 'ramda';
import { useSelector } from 'react-redux';

import { styles } from './LedPanel.styles';

import { selectors } from '~store';
import { StatusText } from '~ui-kit';

export const LedPanel = withStyles(styles)(({ classes }: WithStyles<typeof styles>) => {
  const ledStatuses = useSelector(selectors.app.getLedPanelStatuses, equals);
  const isSelfMonitoring = useSelector(selectors.app.isSelfMonitoring, equals);
  const automationLedLabel = isSelfMonitoring ? 'Self Monitoring' : 'Automation';
  const automationLedStatus = isSelfMonitoring ? 'success' : ledStatuses.automation;
  return (
    <div className={classes.root}>
      {[
        {
          children: [
            {
              label: 'CPU',
              status: ledStatuses.cpu,
              tooltip:
                'CPU load indication, RED when there are Server issues (e.g., buffering issues, catastrophic failure with the server)',
            },
            {
              label: 'Ethernet',
              status: ledStatuses.ethernet,
              tooltip: 'IP Links connection status, RED when there are missing check-in from IP-Link or IP Subscriber',
            },
          ],
          props: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          } as Partial<BoxProps>,
          className: 'left',
        },
        {
          children: [
            {
              label: automationLedLabel,
              status: automationLedStatus,
              tooltip:
                'AA connection status, RED when it is not possible to get Acknowledgements from a designated alarm monitoring system',
            },
            {
              label: 'RF Interference',
              status: ledStatuses.rf,
              tooltip: 'RF Network interference status, RED when an RF interference condition exists',
            },
          ],
          props: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            overflow: 'hidden',
          } as Partial<BoxProps>,
          className: '',
        },
      ].map((box, index) => (
        <Box key={index} {...box.props} className={box.className}>
          {box.children.map(item => (
            <Tooltip key={item.label} title={item.tooltip}>
              <StatusText key={item.label} status={item.status} classes={{ root: clsx(classes.status) }}>
                {item.label}
              </StatusText>
            </Tooltip>
          ))}
        </Box>
      ))}
    </div>
  );
});
