
import { BusinessUnit } from './businessUnit';
import { Permission } from './permission';
import { Profile } from './profile';
import { UnitType } from './unit';

export type UserRole = 'ROLE_TECH_SUPPORT' | 'ROLE_ADMIN' | 'ROLE_TIER_ONE' | 'ROLE_TIER_TWO' | 'ROLE_TIER_THREE' | 'KIOSK';
type UserMeta = {
  isLoading?: boolean;
};

export type User = Omit<Profile, 'profilePhoto'> & {
  phoneNumber?: string;
  role: UserRole;
  permissions: Permission['name'][];
  businessUnits: UserBusinessUnits[];
  fullHoursTimeFormat?: boolean;
  isOnline?: boolean;
  /** Timestamp */
  lastLogin?: number;
  /** Seconds */
  sessionDuration?: number;
  meta?: UserMeta;
  updateRequired: boolean;
  viewAllUnits: boolean;
};

export type UserHistoryActionType =
  | 'LOGIN'
  | 'ADD_USER'
  | 'DELETE_USER'
  | 'ADD_BUSINESS_UNIT'
  | 'DELETE_BUSINESS_UNIT'
  | 'ADD_UNIT'
  | 'DELETE_UNIT';

export type UserHistory = {
  action: {
    label: string;
    value: UserHistoryActionType;
  };
  businessUnit: {
    id: number;
    name: string;
  };
  initiator: {
    id: number;
    name: string;
  };
  timestamp: number;
  unit: {
    businessUnitId: number;
    id: number;
    nonAesUnitId: string;
    unitType: UnitType;
  };
  user: {
    id: number;
    name: string;
  };
};

export type UserBusinessUnits = {
  id: BusinessUnit['id'],
  name: string,
  units: number[],
  ipLinks: number[],
  dealer: {
    id: number,
  } | null
}

export enum UserAssignment {
  EMPTY,
  UNIT,
  IP_LINK,
  DEALER
}
