import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& .left': {
        paddingRight: theme.spacing(2),
      },
    },
    status: {
      cursor: 'pointer',
    },
  });
