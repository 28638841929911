export const links = [
  {
    type: 'alerts',
    title: 'Alerts',
    label: 'Active',
  },
  {
    type: 'unacknowledged',
    title: 'Unacknowledged',
    label: 'Active',
  },
  {
    type: 'connectivity',
    title: 'Connectivity',
    label: 'Down',
  },
];
