export enum PermissionName {
    'DASHBOARD_VIEW' = 'DASHBOARD_VIEW',
    'DASHBOARD_ACKNOWLEDGE' = 'DASHBOARD_ACKNOWLEDGE',
    'DASHBOARD_ACKNOWLEDGE_ALL' = 'DASHBOARD_ACKNOWLEDGE_ALL',
    'DASHBOARD_SILENCE' = 'DASHBOARD_SILENCE',
    'DASHBOARD_EXPORT' = 'DASHBOARD_EXPORT',
    'DEALER_VIEW' = 'DEALER_VIEW',
    'DEALER_ADD_UNIT' = 'DEALER_ADD_UNIT',
    'DEALER_EDIT_UNIT' = 'DEALER_EDIT_UNIT',
    'DEALER_DELETE_UNIT' = 'DEALER_DELETE_UNIT',
    'EDIT_ANTENNA_LIST' = 'EDIT_ANTENNA_LIST',
    'PROFILE_VIEW' = 'PROFILE_VIEW',
    'PROFILE_EDIT' = 'PROFILE_EDIT',
    'PROFILE_STORAGE_SETTINGS' = 'PROFILE_STORAGE_SETTINGS',
    'PROFILE_CHANGE_PASSWORD' = 'PROFILE_CHANGE_PASSWORD',
    'PROFILE_LICENSE_DETAILS' = 'PROFILE_LICENSE_DETAILS',
    'BU_VIEW' = 'BU_VIEW',
    'BU_ADD_UNIT' = 'BU_ADD_UNIT',
    'BU_EDIT_UNIT' = 'BU_EDIT_UNIT',
    'BU_DELETE_UNIT' = 'BU_DELETE_UNIT',
    'BU_VIEW_LIVE_TRAFFIC' = 'BU_VIEW_LIVE_TRAFFIC',
    'SUBSCRIBERS_VIEW' = 'SUBSCRIBERS_VIEW',
    'SUBSCRIBERS_ADD_UNIT' = 'SUBSCRIBERS_ADD_UNIT',
    'SUBSCRIBERS_EDIT_UNIT' = 'SUBSCRIBERS_EDIT_UNIT',
    'SUBSCRIBERS_DELETE_UNIT' = 'SUBSCRIBERS_DELETE_UNIT',
    'SUBSCRIBERS_VIEW_LIVE_TRAFFIC' = 'SUBSCRIBERS_VIEW_LIVE_TRAFFIC',
    'SUBSCRIBERS_ASSIGN_UNIT' = 'SUBSCRIBERS_ASSIGN_UNIT',
    'SET_DEFAULT_BU' = 'SET_DEFAULT_BU',
    'IP_LINK_VIEW' = 'IP_LINK_VIEW',
    'IP_LINK_ADD_UNIT' = 'IP_LINK_ADD_UNIT',
    'IP_LINK_EDIT_UNIT' = 'IP_LINK_EDIT_UNIT',
    'IP_LINK_DELETE_UNIT' = 'IP_LINK_DELETE_UNIT',
    'IP_LINK_ASSIGN_UNIT' = 'IP_LINK_ASSIGN_UNIT',
    'USER_VIEW' = 'USER_VIEW',
    'USER_ADD' = 'USER_ADD',
    'USER_EDIT' = 'USER_EDIT',
    'USER_DELETE' = 'USER_DELETE',
    'GEO_VIEW' = 'GEO_VIEW',
    'IMPORT_EXPORT_VIEW' = 'IMPORT_EXPORT_VIEW',
    'IMPORT_EXPORT_IMPORT' = 'IMPORT_EXPORT_IMPORT',
    'IMPORT_EXPORT_EXPORT' = 'IMPORT_EXPORT_EXPORT',
    'SETTINGS_VIEW' = 'SETTINGS_VIEW',
    'LIVE_TRAFFIC_VIEW' = 'LIVE_TRAFFIC_VIEW',
    'VIEW_ALL_UNITS' = 'VIEW_ALL_UNITS',
    'CONTROL_RELAY_VIEW' = 'CONTROL_RELAY_VIEW',
    'CONTROL_RELAY_ADD' = 'CONTROL_RELAY_ADD',
    'CONTROL_RELAY_EDIT' = 'CONTROL_RELAY_EDIT',
    'CONTROL_RELAY_DELETE' = 'CONTROL_RELAY_DELETE',
    'FACP_VIEW' = 'FACP_VIEW',
    'FACP_EDIT' = 'FACP_EDIT',
}

export type Permission = {
  groupName: string;
  id: number;
  label: string;
  name: PermissionName | string;
  parent: boolean;
  disabled: boolean;
};
